import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  fontSize,
  fontWeights,
  brandFonts,
  sectionPaddings,
  sectionMargins,
  standardColours,
  brandColours,
  maxBreakpointQuery,
  formButtonStyles,
  standardTransition,
  visuallyHidden,
} from '../styles';
import { Container, Button, Svg } from './ui';
import facebookIcon from '../images/facebook-icon.inline.svg';
import instagramIcon from '../images/instagram-icon.inline.svg';
import linkedinIcon from '../images/linkedin-icon.inline.svg';
import twitterIcon from '../images/twitter-icon.inline.svg';

const StyledContactFormInformation = styled.div`
  ${minBreakpointQuery.mlarge`
    background: linear-gradient(
      to left,
      ${standardColours.grey} 50%,
      ${standardColours.white} 50%
    );
  `}
`;

const StyledInner = styled.div`
  ${minBreakpointQuery.mlarge`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  `}
`;

const StyledContact = styled.section`
  ${sectionMargins()}

  ${minBreakpointQuery.mlarge`
    padding-right: 40px;
  `}

  ${minBreakpointQuery.large`
    padding-right: 50px;
  `}

  ${minBreakpointQuery.xlarge`
    padding-right: 60px;
  `}
`;

const StyledInformation = styled.section`
  ${sectionPaddings()}

  ${maxBreakpointQuery.mlarge`
    background-color: ${standardColours.grey};
    padding-left: 30px;
    padding-right: 30px;
    margin-left: -30px;
    margin-right: -30px;
  `}

  ${minBreakpointQuery.mlarge`
    padding-left: 40px;
  `}

  ${minBreakpointQuery.large`
    padding-left: 50px;
  `}

  ${minBreakpointQuery.xlarge`
    padding-left: 60px;
  `}
`;

const StyledHeader = styled.header``;

const StyledHeading = styled.h2`
  ${fontSize(22)};
  margin-bottom: 10px;

  ${minBreakpointQuery.medium`
    ${fontSize(26)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(32)};
  `}
`;

const StyledText = styled.p`
  ${fontSize(14)};
`;

const StyledForm = styled.form`
  display: grid;
  margin-top: 40px;
  row-gap: 30px;

  ${minBreakpointQuery.mlarge`
    margin-top: 50px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 60px;
  `}

  ${minBreakpointQuery.xlarge`
    margin-top: 80px;
  `}
`;

const StyledLabel = styled.label`
  grid-column: 1 / 3;
  ${fontSize(14)};
  font-weight: ${fontWeights.medium};

  ${({ honeypot }) => {
    if (honeypot) {
      return css`
        ${visuallyHidden()};
      `;
    }
  }}
`;

const StyledInput = styled.input`
  ${fontSize(16)};
  font-family: ${brandFonts.primary};
  font-weight: ${fontWeights.medium};
  width: 100%;
  border: none;
  border-bottom: 1px solid ${standardColours.grey};
  padding: 15px 0;
  margin-top: 10px;
`;

const StyledTextarea = styled.textarea`
  ${fontSize(16)};
  font-family: ${brandFonts.primary};
  font-weight: ${fontWeights.medium};
  width: 100%;
  border: 1px solid ${standardColours.grey};
  margin-top: 20px;
  padding: 25px;
  min-height: 120px;
  resize: vertical;

  ${minBreakpointQuery.mlarge`
    min-height: 160px;
  `}
`;

const StyledSubmit = styled(Button)`
  ${formButtonStyles()}
  grid-column: 1 / 2;
`;

const StyledGroup = styled.div`
  ${fontSize(14)};
  line-height: 1.7;
  margin-top: 40px;

  ${minBreakpointQuery.mlarge`
    margin-top: 50px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 60px;
  `}

  ${minBreakpointQuery.xlarge`
    margin-top: 80px;
  `}
`;

const StyledContactInfo = styled.div`
  margin-top: 40px;
  margin-bottom: 30px;
`;

const StyledSpan = styled.span`
  font-weight: ${fontWeights.semibold};
  ${({ alt }) => {
    if (alt) {
      return css`
        display: block;
      `;
    }
  }}
`;

const StyledAddress = styled.p`
  white-space: break-spaces;
`;

const StyledSocial = styled.div`
  margin-top: 30px;
`;

const StyledSocialLink = styled.a`
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }
`;

const StyledIcon = styled(Svg)`
  width: 18px;
  height: 18px;
  fill: ${brandColours.primary};
  transition: ${standardTransition('fill')};

  &:hover {
    fill: ${brandColours.septenary};
  }
`;

const ContactFormInformation = () => {
  const {
    datoCmsContactInformation: {
      email,
      phone,
      address,
      facebookProfile,
      instagramProfile,
      linkedinProfile,
      twitterProfile,
    },
  } = useStaticQuery(graphql`
    query ContactFormInformationQuery {
      datoCmsContactInformation {
        email
        phone
        address
        facebookProfile
        instagramProfile
        linkedinProfile
        twitterProfile
      }
    }
  `);

  return (
    <StyledContactFormInformation>
      <Container>
        <StyledInner>
          <StyledContact>
            <StyledHeader>
              <StyledHeading>General contact</StyledHeading>
              <StyledText>Our team will get back to you soon.</StyledText>
            </StyledHeader>
            <StyledForm
              id="contact-form"
              name="contact-form"
              method="POST"
              action="/thanks/"
              netlify-honeypot="question"
              data-netlify="true"
            >
              <input type="hidden" name="form-name" value="contact-form" />
              <StyledLabel honeypot={true}>
                <StyledInput type="text" name="question" />
              </StyledLabel>
              <StyledLabel>
                First Name*
                <StyledInput
                  type="text"
                  name="first-name"
                  placeholder="First Name"
                  required
                />
              </StyledLabel>
              <StyledLabel>
                Last Name*
                <StyledInput
                  type="text"
                  name="last-name"
                  placeholder="Last Name"
                  required
                />
              </StyledLabel>
              <StyledLabel>
                Email Address*
                <StyledInput
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  required
                />
              </StyledLabel>
              <StyledLabel>
                Message*
                <StyledTextarea name="message" placeholder="Message" required />
              </StyledLabel>
              <StyledSubmit type="submit">Make an enquiry</StyledSubmit>
            </StyledForm>
          </StyledContact>
          <StyledInformation>
            <StyledHeader>
              <StyledHeading>Get in touch</StyledHeading>
            </StyledHeader>
            <StyledGroup>
              <StyledContactInfo>
                <p>
                  <StyledSpan>Email:</StyledSpan>
                  {` ${email}`}
                </p>
                <p>
                  <StyledSpan>Phone:</StyledSpan>
                  {` ${phone}`}
                </p>
              </StyledContactInfo>
              <StyledAddress>
                <StyledSpan alt={true}>Address:</StyledSpan>
                {address}
              </StyledAddress>
              <StyledSocial>
                <StyledSocialLink
                  href={facebookProfile}
                  target={`_blank`}
                  rel={`noopener noreferrer`}
                >
                  <StyledIcon image={facebookIcon} title={`Facebook`} />
                </StyledSocialLink>
                <StyledSocialLink
                  href={instagramProfile}
                  target={`_blank`}
                  rel={`noopener noreferrer`}
                >
                  <StyledIcon image={instagramIcon} title={`Instagram`} />
                </StyledSocialLink>
                <StyledSocialLink
                  href={linkedinProfile}
                  target={`_blank`}
                  rel={`noopener noreferrer`}
                >
                  <StyledIcon image={linkedinIcon} title={`Linkedin`} />
                </StyledSocialLink>
                <StyledSocialLink
                  href={twitterProfile}
                  target={`_blank`}
                  rel={`noopener noreferrer`}
                >
                  <StyledIcon image={twitterIcon} title={`Twitter`} />
                </StyledSocialLink>
              </StyledSocial>
            </StyledGroup>
          </StyledInformation>
        </StyledInner>
      </Container>
    </StyledContactFormInformation>
  );
};

export default ContactFormInformation;
