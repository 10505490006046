import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ContactFormInformation from '../components/ContactFormInformation';
import ModularBlocks from '../components/ModularBlocks';

const ContactPage = () => {
  const {
    datoCmsContact: {
      seoMetaTags,
      title,
      bannerImage,
      bannerText,
      modularBlocks,
    },
  } = useStaticQuery(graphql`
    query ContactPageQuery {
      datoCmsContact {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerImage {
          ...SubPageBannerImageFragment
        }
        bannerText
        modularBlocks {
          ...AccordionModularBlockFragment
          ...ContentFeaturedPointsModularBlockFragment
          ...ContentModularBlockFragment
          ...DonationCtaModularBlockFragment
        }
      }
    }
  `);

  return (
    <Layout isContactPage={true}>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner
          heading={title}
          image={bannerImage}
          text={bannerText}
          isContactPage={true}
        />
        <ContactFormInformation />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export default ContactPage;
